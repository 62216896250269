.clienthasayettoshippedorder {
  width: 100%;
  background-color: #1d2027;
  /* background-color: #d9ecd9;
  background-image: linear-gradient(
    to right,
    rgb(0, 128, 0, 0.25),
    #ff9934,
    rgb(0, 128, 0, 0.25)
  );
  background-image: linear-gradient(to right, #ff9935, rgb(255, 153, 53, 0.15));*/
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  text-align: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  animation: fadein 0.4s forwards;
  font-family: Arial, Helvetica, sans-serif;
}
.topbar {
  width: 100%;
  /*background-color: #d9ecd9;
  background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d);*/
  background-color: #1d2027;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  text-align: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  animation: fadein 0.4s forwards;
  font-family: Arial, Helvetica, sans-serif;
}
@keyframes fadein {
  from {
    height: 0;
  }
  to {
    height: 4rem;
  }
}
.clienthasayettoshippedorder h1 {
  width: 12.5%;
  background-color: #1d2027;
  color: #ff9934;
  font-size: 1rem;
  padding: 0.25rem;
  letter-spacing: 0.05rem;
  text-shadow: 1px 1px 1px rgb(131, 131, 131);
  border: 1px solid rgb(173, 255, 47);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px greenyellow;
  border-radius: 0.25rem;
  text-align: center;
  margin: 0 0.25rem 0 0.25rem;
}
.topbar h1 {
  width: 12.5%;
  background-color: #1d2027;
  color: #ff9934;
  font-size: 1rem;
  padding: 0.5rem 0.25rem;
  letter-spacing: 0.05rem;
  border: 1px solid #6fcabb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px greenyellow;
  border-radius: 0.25rem;
  text-align: center;
  margin: 0 0.25rem 0 0.25rem;
}
.topbar a {
  color: #ff9935;
}
.clienthasayettoshippedorder ul,
.topbar ul {
  width: 30%;
  color: #6fcabb;
  font-size: 0.65rem;
  text-align: justify;
  margin-right: 0.25rem;
}
.clienthasayettoshippedorder li,
.topbar li {
  font-size: 0.675rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0.1rem 0;
}
.clienthasayettoshippedorder i,
.topbar i {
  margin: 0.1rem;
  color: palevioletred;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 800px) {
  .clienthasayettoshippedorder,
  .topbar {
    top: 4rem;
  }
  .clienthasayettoshippedorder h1,
  .topbar h1 {
    width: 24%;
    font-size: 0.8rem;
    padding: 0.2rem 0.1rem;
    text-align: left;
    line-height: 1rem;
  }
  .clienthasayettoshippedorder ul,
  .topbar ul {
    width: 90%;
  }
}
