.item-list table:last-child {
  margin-bottom: 9rem; /* Adjust the margin as needed */
}
.cart-summary-container {
  font-size: 0.85rem;
  width: 100%;
  height: 4rem;
  bottom: 0;
  margin-top: 0.25rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.bank-transfer-banks-container {
  font-size: 0.85rem;
  width: 100%;
  bottom: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.bank-transfer-notes-container {
  font-size: 0.85rem;
  width: 100%;
  bottom: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
