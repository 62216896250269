.cart-summary-component {
  width: 100%;
  font-size: 1.25rem;
  font-family: Helvetica, Arial, sans-serif;
}

.cart-summary-component table {
  width: 95%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1rem 0;
}

.cart-summary-component th {
  background-color: rgb(233, 233, 233);
  font-size: 1rem;
  vertical-align: middle;
}

.cart-summary-component tr {
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.cart-summary-component td {
  border: none;
  border-bottom: 1px solid rgb(233, 233, 233);
  vertical-align: middle;
}

.cart-summary-component th,
.cart-summary-component td {
  text-align: left;
  padding: 0.5rem;
}

.cart-summary-component th {
  font-weight: bold;
}

.cart-summary-component td {
  font-weight: normal;
}

.cart-summary-component tr:last-child td {
  border-bottom: none;
}
