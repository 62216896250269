.unpaid-orders-message-area {
  top: 1rem;
  position: fixed;
  z-index: 10;
  max-height: 80%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  width: 76%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 0.1rem solid red;
}
.unpaid-orders-message-flex {
  width: 100%;
  min-height: 4rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.unpaid-orders-view-order {
  cursor: pointer;
  background-color: #ff9935;
  width: 100%;
  color: white;
  font-size: 1.15rem;
  font-weight: bold;
  height: 2rem;
  cursor: pointer;
  border: 0;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}
.unpaid-orders-view-order:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.unpaid-orders-unview-order {
  cursor: pointer;
  background-color: lightgreen;
  width: 50%;
  font-size: 1.15rem;
  font-weight: bold;
  height: auto;
  cursor: pointer;
  border: 0;
}
.unpaid-orders-unview-order:hover {
  transition: background-color 0.5s ease;
  background-color: black;
  color: wheat;
}
.unpaid-orders-unview-area {
  min-height: 2.5rem;
  width: 95%;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: whitesmoke;
}
.unpaid-orders-area {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-content: space-evenly;
  align-items: baseline;
  float: right;
  width: 100%;
  border-top: 0.1rem solid #ff9935;
  border-bottom: 0.1rem solid #ff9935;
  overflow-y: auto;
}
.unpaid-orders-box {
  height: auto;
  width: 30%;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.unpaid-orders-box thead {
  height: 2rem;
}
.unpaid-orders-box th,
td {
  vertical-align: middle;
  text-align: center;
}
.unpaid-headline {
  font-size: 1.25rem;
  font-weight: bold;
  background-color: #f5f7fa;
  height: 3rem;
}
.unpaid-order-pay {
  background-color: #ff9935;
  width: 100%;
  color: white;
  font-size: 1.15rem;
  font-weight: bold;
  height: 2.5rem;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.unpaid-order-pay:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.unpaid-order-edit {
  background-color: green;
  width: 100%;
  color: white;
  font-size: 1.15rem;
  font-weight: bold;
  height: 2.5rem;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.unpaid-order-edit:hover {
  transition: background-color 0.5s ease;
  background-color: rgb(6, 173, 6);
}
.unpaid-order-delete {
  background-color: gray;
  width: 100%;
  color: white;
  font-size: 1.15rem;
  font-weight: bold;
  height: 2.5rem;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.unpaid-order-delete:hover {
  transition: background-color 0.5s ease;
  background-color: #000;
}
.unpaid-item-block {
  background-color: rgba(252, 173, 95, 0.1);
  font-size: 0.85rem;
}
.lighter {
  background-color: transparent;
}
.unpaid-item-name {
  height: 3rem;
  vertical-align: middle;
}
.unpaid-item-spec {
  color: green;
}
.unpaid-image-row {
  height: 4rem;
}
.unpaid-image {
  vertical-align: middle;
  width: 22.5%;
  border-right: 0.25rem solid transparent;
}
.unpaid-price-offer {
  vertical-align: middle;
  text-align: left !important;
}
.unpaid-orders-qty {
  border-left: 0.25rem solid transparent;
}
.unpaid-orders-empty-row {
  background-color: #ff9935;
  height: 0.15rem;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 1024px) {
  .unpaid-orders-message-area {
    width: 85%;
    top: 5rem;
  }
  .unpaid-orders-view-order {
    height: auto;
  }
  .unpaid-orders-area {
    justify-content: center;
    width: 100%;
    float: none;
  }
  .unpaid-orders-box {
    height: auto;
    width: 35%;
    margin: 1rem 1rem;
    background-color: seashell;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .unpaid-item-block {
    font-size: 1.25rem;
  }
  .unpaid-orders-unview-order {
    width: 95%;
  }
}
@media only screen and (max-width: 800px) {
  .unpaid-orders-box {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .unpaid-orders-box {
    width: 65%;
  }
}
@media only screen and (max-width: 360px) {
  .unpaid-orders-box {
    width: 75%;
  }
}
