.pop-modal-background {
  background-color: rgb(0, 0, 0, 0.75);
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pop-modal-front {
  position: relative;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  border-radius: 0.5rem;
  opacity: 0;
}
.pop-modal-front-active {
  opacity: 1;
  transition: opacity 1500ms;
}
.pop-close-icon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.5rem;
  color: #ff9935;
  cursor: pointer;
  margin: 1rem;
}

@media only screen and (max-width: 800px) {
  .pops-modal-front,
  .pop-modal-front-active {
    width: 95% !important;
    height: auto !important;
    max-height: 95% !important;
    display: inline-block;
    flex-direction: unset;
    justify-content: unset;
    align-items: unset;
    overflow: scroll;
  }
  .pops-modal-front,
  .pop-modal-front-active h1 {
    width: 95%;
    text-align: center;
  }
  .pops-content-div {
    width: 95% !important;
    height: 95% !important;
    display: inline-block;
    flex-direction: unset;
    justify-content: unset;
  }
}
