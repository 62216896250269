.header-area-mobile {
  display: none;
}

.header-area-desktop {
  width: 20%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #fff;
}

.header-area-desktop h1 {
  width: 100%;
  padding: 1rem 0;
  font-size: 1.25rem;
  font-weight: bold;
  background-color: #f5f7fa;
  text-align: center;
}

/* LOGO BOX SECTION */

figcaption {
  font-weight: bold;
}

.logo-box {
  top: 6rem;
  width: 100%;
  min-height: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: italic;
  position: absolute;
}

.logo-box img {
  margin: 0.5rem;
  width: 50%;
}

.logo-box a {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: black;
  width: 100%;
}

/* MENU BOX SECTION */

.menu-box {
  top: 13rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  position: absolute;
}

.nav-links.nav-links-active {
  border-bottom: 2px solid #ff9935;
  display: inline-block;
}
.alternative-navlinkactive-effect {
  border-bottom: 2px solid #ff9935;
  display: inline-block;
}
.menu-box ul,
.customer-support-box ul {
  width: 70%;
  overflow: hidden;
}

.menu-box li,
.customer-support-box li {
  width: 100%;
  padding: 0.5rem;
}

a.nav-links,
i {
  padding: 0.05rem;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  list-style: none;
  color: black;
  font-size: 1.25rem;
}

.fa.fa-bars {
  display: none;
}

/* CART SECTION */

.cart-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f5f7fa;
}

.user-info {
  width: 88%;
  position: absolute;
  text-align: center;
  background-color: #f5f7fa;
}

.cart-icon {
  width: 3rem;
  height: 3rem;
  float: right;
  position: relative;
}

.cart-length,
.fa.fa-shopping-cart {
  float: right;
  position: absolute;
}

.fa.fa-shopping-cart {
  font-size: 2.15rem;
  padding: 0.25rem;
  color: #ff9935;
}

.cart-length {
  width: 1rem;
  height: 0.85rem;
  border-radius: 100%;
  font-size: 0.85rem;
  font-weight: bold;
  color: red;
  margin-top: 0.65rem;
  margin-left: 1.1rem;
  text-align: center;
  vertical-align: middle;
  background-color: white;
}

/* CUSTOMER SUPPORT SECTION */

.customer-support-box {
  top: 21rem;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fas.fa-sign-out-alt {
  padding: 0.5rem;
  color: green;
}

.fas.fa-sign-out-alt.fa-flip-horizontal {
  color: red;
  padding: 0;
  margin: 0 0.5rem 0 0;
  vertical-align: middle;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 1024px) {
  .header-area-desktop {
    display: none;
  }
  .header-area-mobile {
    width: 100%;
    height: 4rem;
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1000;
  }
  .bars {
    width: 13%;
  }
  .logo {
    width: 15%;
  }
  .username {
    width: 52%;
  }
  .cart-mobile {
    width: 20%;
    margin-right: 1rem;
  }
}
