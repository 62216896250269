.scrolltotop {
  position: fixed;
  z-index: 10;
  bottom: 4rem;
  right: 2rem;
  cursor: pointer;
  color: #ff9935;
  font-size: 3rem;
}

@media only screen and (max-width: 260px) {
  .scrolltotop {
    right: 0;
  }
}
