.countdown-timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 1.25rem;
  height: 100%;
  color: #808080;
  margin: 1rem 0;
}
.topBarStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  height: 100%;
  color: #808080;
  margin: 1rem 0;
}
.countdown-timer-withday {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 1.25rem;
  height: 100%;
  color: #808080;
}
.countdown-timer-withouthour {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 2rem;
  height: 100%;
  color: #808080;
}
.countdown-timer span {
  padding: 0 5px;
}

.countdown-timer .separator {
  opacity: 0.6;
}

.countdown-timer .days,
.countdown-timer .hours,
.countdown-timer .minutes,
.countdown-timer .seconds {
  animation: countdown 1s linear infinite;
}

.countdown-timer .minutes {
  animation-delay: -1s;
}

.countdown-timer .seconds {
  animation-delay: -2s;
}

/* Media query for screens with a maximum width of 600 pixels */
@media (max-width: 240px) {
  .countdown-timer {
    font-size: 1rem;
  }
  .countdown-timer-withday {
    font-size: 0.65rem;
  }
  .countdown-timer-withouthour {
    font-size: 1.5rem;
  }
}
@media (min-width: 241px) and (max-width: 280px) {
  .countdown-timer {
    font-size: 1.25rem;
  }
  .countdown-timer-withday {
    font-size: 0.85rem;
  }
  .countdown-timer-withouthour {
    font-size: 1.75rem;
  }
}
@media (min-width: 281px) and (max-width: 320px) {
  .countdown-timer {
    font-size: 1.5rem;
  }
  .countdown-timer-withday {
    font-size: 1rem;
  }
  .countdown-timer-withouthour {
    font-size: 2rem;
  }
}
@media (min-width: 321px) and (max-width: 360px) {
  .countdown-timer {
    font-size: 1.65rem;
  }
  .countdown-timer-withday {
    font-size: 1.2rem;
  }
  .countdown-timer-withouthour {
    font-size: 2.25rem;
  }
}
@media (min-width: 361px) and (max-width: 375px) {
  .countdown-timer {
    font-size: 1.75rem;
  }
  .countdown-timer-withday {
    font-size: 1.25rem;
  }
  .countdown-timer-withouthour {
    font-size: 2.5rem;
  }
}
@media (min-width: 376px) and (max-width: 390px) {
  .countdown-timer {
    font-size: 1.85rem;
  }
  .countdown-timer-withday {
    font-size: 1.35rem;
  }
  .countdown-timer-withouthour {
    font-size: 2.65rem;
  }
}
@media (min-width: 391px) and (max-width: 414px) {
  .countdown-timer {
    font-size: 1.95rem;
  }
  .countdown-timer-withday {
    font-size: 1.45rem;
  }
  .countdown-timer-withouthour {
    font-size: 2.75rem;
  }
}
@media (min-width: 415px) and (max-width: 540px) {
  .countdown-timer {
    font-size: 2.5rem;
  }
  .countdown-timer-withday {
    font-size: 2rem;
  }
}
