.suspense-shadow-platform {
  width: 100%;
  height: 100vh;
  background-color: #ff9935;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  color: white;
}
.suspense-shadow-platform i {
  margin-right: 0.5rem;
}
