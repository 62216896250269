.cart-shadow-platform-for-heading {
  width: 95%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0 auto;
  margin-top: 1rem;
}
.cart-shadow-platform {
  width: 95%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  align-items: baseline;
}
.cart-shadow-table-platform-holder {
  width: 25rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
}
.cart-summary-holder {
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 1rem;
}
.cart-shadow-table-platform {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 1rem 0;
}

.cart {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  float: right;
  width: 95%;
}

/*
.cart-shadow-platform thead,
.cart-shadow-platform tfoot {
  background-color: #f5f7fa;
}
*/

.cart-shadow-platform tfoot {
  text-align: center;
  font-size: 1.15rem;
  font-weight: bold;
}

.cart-shadow-platform tr {
  border-top: 1rem solid #fff;
}

.cart-shadow-platform td {
  display: table-cell;
  vertical-align: middle;
}

select {
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 0.85rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cart-image {
  max-width: 5rem;
  height: auto;
  margin: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 1024px) {
  .cart-shadow-platform {
    width: 85%;
    background-color: #f5f7fa;
  }
  .cart-shadow-platform-for-heading {
    width: 85%;
  }
}
@media only screen and (max-width: 600px) {
  .cart-shadow-platform {
    box-shadow: none;
  }
  .cart-shadow-table-platform-holder {
    width: 100%;
  }
  .cart-summary-holder {
    width: 100%;
    margin-top: 1rem;
  }
  .cart-shadow-table-platform {
    width: 100%;
    margin-bottom: 1rem;
  }
  .cart-image {
    max-width: 3.5rem;
  }
}
@media only screen and (max-width: 280px) {
  .cart-shadow-platform {
    width: 87.5%;
  }
  .cart-image {
    max-width: 3rem;
  }
}
@media only screen and (max-width: 240px) {
  .cart-shadow-platform {
    width: 87.5%;
  }
  .cart-shadow-platform-for-heading {
    width: 87.5%;
  }
  .cart-image {
    max-width: 2.5rem;
  }
}
