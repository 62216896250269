.product-feature {
  width: 85%;
  color: #8063a1;
  font-weight: bolder;
  text-align: center;
  margin: 2rem 0;
  font-size: 1rem;
  animation: pulsate 1.5s infinite alternate;
  border-bottom: 0.1rem solid green;
  border-radius: 0.1rem;
  padding: 0.4em;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .product-feature {
    width: 90%;
    margin-top: 1.75rem;
    font-size: 0.85rem;
  }
}
