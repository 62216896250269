.footer-shadow-platform {
  width: 95%;
  border-radius: 0.5rem;
  background-color: rgb(255, 153, 53, 0.1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: flex-start;
}
.footer-news-div {
  font-size: 0.85rem;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  border-bottom: 0.1rem solid #ff9935;
}
.footer-news-div-beddo-logo {
  width: 3rem;
  margin: 1rem 0 1rem 1rem;
}
.footer-news-div-media-logo {
  height: 1.25rem;
  margin: 1rem 0 1rem 1rem;
}
.footer-news-div-h3 {
  margin: 1rem 1rem 1rem 0.5rem;
}
.footer-four-reason-div {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 0.1rem solid #ff9935;
}
.footer-four-reason-table {
  margin: 1rem 0;
  width: 25%;
}
.footer-four-reason-table tr {
  width: 100%;
}
.footer-four-reason-first-td {
  text-align: left;
  vertical-align: top;
}
.footer-four-reason-second-td {
  text-align: left;
  vertical-align: middle;
}
.footer-four-reason-table i {
  font-size: 2rem;
  color: #ff9935;
  margin: 0.5rem;
}
.footer-four-reason-table h2 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.footer-four-reason-table p {
  font-size: 1rem;
}
.footer-navigation-div {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #ff9935;
}
.footer-navigation-div ul {
  width: 100%;
  margin: 1rem;
  font-size: 1rem;
}
.footer-navigation-div a {
  text-decoration: none;
  color: black;
}
.footer-navigation-div a:hover {
  text-decoration: none;
  color: #5e5c5a;
}
.footer-navigation-div-div {
  text-decoration: none;
  color: black;
}
.footer-navigation-div-div:hover {
  text-decoration: none;
  color: #ff9935;
}
.footer-navigation-div li {
  width: 95%;
  line-height: 2.5rem;
  border-bottom: 0.1rem solid rgb(128, 128, 128, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footer-navigation-div-active li {
  line-height: 2.5rem;
  border-bottom: 0.1rem solid #ff9935;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footer-navigation-div span {
  font-size: 1rem !important;
  line-height: 2.5rem;
}
.footer-navigation-div i {
  font-size: 1.5rem;
}
.footer-chevron {
  color: #ff9935;
  font-size: 0.75rem !important;
  margin-right: 0.5rem;
}
.footer-company-div {
  font-size: 0.85rem;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
}
.footer-logo img {
  width: 6rem;
}
.footer-logo,
.footer-copyright,
.footer-address {
  margin: 1rem;
}
.footer-iyzico-image {
  width: 17.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 599px) {
  .footer-shadow-platform {
    width: 95%;
    margin: 0;
  }
  .footer-four-reason-div {
    flex-wrap: wrap;
  }
  .footer-four-reason-table {
    width: 47.5%;
  }
}
@media only screen and (max-width: 539px) {
  .footer-shadow-platform {
    width: 90%;
  }
  .footer-navigation-div {
    flex-wrap: wrap;
  }
  .footer-navigation-div ul {
    margin: 0 0 0 1rem;
  }
}
@media only screen and (max-width: 385px) {
  .footer-four-reason-table {
    width: 90%;
  }
  .footer-four-reason-table i {
    min-width: 3rem;
  }
}
@media only screen and (max-width: 340px) {
  .footer-iyzico-image {
    width: 15.5rem;
    margin-bottom: 0.5rem;
  }
}
@media only screen and (max-width: 280px) {
  .footer-iyzico-image {
    width: 12rem;
    margin-bottom: 0.5rem;
  }
}
