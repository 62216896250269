.product-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
}

.image {
  width: 70%;
  height: 70%;
  margin: 0;
  display: block;
  text-align: center;
}

.product-name {
  background-color: #f5f7fa;
  height: 4.5rem;
  font-size: 2rem;
  margin: 1rem 0.75rem 0.25rem 0.75rem;
}

.rating {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.rating-text {
  font-size: 0.85rem;
  color: grey;
}

.price {
  font-size: 1.5rem;
}

.countinstock {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.options {
  height: 1.5rem;
  width: 100%;
  border: 1px solid #ff9935;
  background-color: seashell;
}

.image a {
  display: inline-block;
  width: 100%;
}

.info a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  list-style: none;
  color: black;
}

.image img {
  width: 82%;
  height: 82%;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 600px) {
  .product-inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .image {
    width: 100%;
  }
  .product-name {
    height: auto;
  }
  .image img {
    width: 100%;
  }
}
