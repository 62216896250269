.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  float: right;
  width: 100%;
}
.product-outer {
  height: auto;
  width: 21%;
  margin: 1rem 0;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 600px) {
  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    float: none;
  }

  .product-outer {
    height: auto;
    width: 95%;
    margin: 1rem 0;
    background-color: seashell;
  }
}
@media only screen and (max-width: 599px) {
  .product-outer {
    height: auto;
    width: 95%;
    margin: 1rem 0;
    background-color: seashell;
  }
}
@media only screen and (max-width: 539px) {
  .product-outer {
    height: auto;
    width: 90%;
    margin: 1rem 0;
    background-color: seashell;
  }
}
@media only screen and (max-width: 280px) {
  .product-outer {
    width: 87.5%;
  }
}
