html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

#root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
  box-sizing: border-box;
}

.iphone-fs {
  font-size: 16px;
}

/* REMOVE ARROWS FROM NUMBER INPUT FIELDS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.grecaptcha-badge {
  visibility: hidden !important;
}
/* REMOVE ON CLICK BORDERS FROM INPUT FIELDS */
input {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

/* APP LEVEL STYLING */
main {
  width: 100%;
  background-color: #f5f7fa;
}
.user-screen {
  float: right;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
}
/* APP LEVEL STYLING MOBILE DEVICES */
@media only screen and (max-width: 600px) {
  #root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
    box-sizing: border-box;
  }
  main {
    width: 100%;
    background-color: #f5f7fa;
    position: relative;
  }
  .header-area {
    display: none;
  }
  .user-screen {
    top: 4rem;
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
  }
  .user-screen-land {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
  }
}
/* ADMIN PANEL SECTION */
.admin {
  width: 100%;
  float: right;
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
/* ADMIN PROFILE NAV COMPONENT */
.admin-profile-nav {
  background-color: #f5f7fa;
  position: fixed;
  top: 4rem;
  right: 2rem;
  width: 15rem;
  border-radius: 0.5rem;
  line-height: 2rem;
  z-index: 2;
  font-size: 1rem;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.admin-profile-nav li {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.admin-profile-nav i {
  font-size: 1.5rem;
  color: red !important;
}
/* ADMIN LEFT NAV COMPONENT */
.admin-left-nav {
  margin-top: 4rem;
  margin-right: auto;
  margin-left: 1rem;
  height: 100%;
}
/* ADMIN PANEL SCREENS */
.admin-panel-accountant-screen {
  margin-top: 3.75rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
/* ADMIN PANEL SCREENS */
.admin-panel-screen {
  margin-top: 3.75rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  width: 85%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.admin-panel-order-items-screen {
  margin-top: 3.75rem;
  width: 100%;
}
/* ADMIN PANEL TABLES */
.admin-panel-table {
  width: 98%;
  padding: 0.5rem 0;
  margin: 0 auto;
  font-size: 1rem;
  text-align: center;
}

.admin-panel-table td,
th {
  text-align: left;
}
.admin-panel-table tr {
  border-bottom: 0.1rem solid rgb(233, 233, 233);
  background-color: white;
  line-height: 2rem;
}
.head-tr {
  background-color: #f5f7fa !important;
}
.inventory-table-head {
  background-color: #f5f7fa !important;
}
.inventory-table-head th {
  border: 3px solid white;
  text-align: center;
  padding: 0 0, 5rem;
}
.admin-panel-table th {
  font-weight: bold;
  line-height: 3rem;
}
.admin-panel-table-left {
  padding: 0.5rem 0;
  font-size: 1rem;
  text-align: left;
}
.admin-panel-table-left td,
th {
  text-align: left;
}
.admin-panel-table-left tr {
  border-bottom: 0.1rem solid rgb(233, 233, 233);
  background-color: white;
  line-height: 2rem;
}
.admin-panel-table-left th {
  font-weight: bold;
  line-height: 3rem;
}

/* AUTHETICATE NEW PHONE */
.authenticatenewphone-background {
  background-color: rgb(0, 0, 0, 0.75);
  float: right;
  width: 80%;
  height: 100vh;
  position: fixed;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.authenticatenewphone {
  max-width: 70%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
.authenticatenewphone h2 {
  max-width: 20rem;
  font-size: 1.1rem;
  margin: 1rem;
}
.authenticatenewphone .icon {
  margin-right: 1rem;
}

/* USER PROFILE DISPLAY */
.user-profile-display h1 {
  font-size: 1.25rem;
}
.user-profile-display h2 {
  font-size: 1rem;
}
.user-profile-display h3 {
  font-size: 0.85rem;
}
.user-profile-display {
  max-width: 15rem;
  height: auto;
  margin: 1rem;
  padding: 0.25rem;
  background-color: #f5f7fa;
  border: 0.1rem solid #ff9935;
  border-radius: 0.5rem;
}

/* MODALS */
.modal,
.dialog {
  background-color: rgb(0, 0, 0, 0.75);
  float: right;
  top: 0;
  width: 150%;
  height: 100vh;
  position: fixed;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ADMIN MODALS */
.admin-modal,
.admin-dialog {
  background-color: rgb(0, 0, 0, 0.75);
  float: right;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-modal-front,
.admin-dialog-front {
  max-width: 30rem;
  max-height: 95%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 800px) {
  .modal,
  .dialog {
    width: 100%;
  }
}
.modal-front,
.dialog-front {
  max-width: 30rem;
  max-height: 95%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem;
}
.dialog-content,
.modal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
  margin: 0.25rem;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.admin-auth-user-phone h2 {
  max-width: 25rem;
  font-size: 1.1rem;
  margin: 1rem;
}
.admin-auth-user-phone .icon {
  margin-right: 1rem;
}

/* //////////////////// GENERAL STYLING VISIBILITY / OVERFLOW \\\\\\\\\\\\\\\\\\\\ */
.hide-display {
  display: none;
}
.hide {
  visibility: hidden;
}
.hidescroll {
  overflow: hidden;
}

/* GENERAL STYLING LINK */
.nodeco {
  text-decoration: none;
}
.linkblue-noborder {
  color: #0645ad;
}
.linkblue {
  color: #0645ad;
}
.linkblue:hover {
  border-bottom: 0.1rem solid #0645ad;
}
.linkblue-background {
  color: #0645ad;
}
.linkblue-background:hover {
  background-color: lightgreen;
}

/* GENERAL STYLING IMAGE */
.large-image {
  width: 50%;
}
.cart-product-img-noshadow {
  max-width: 5rem;
  height: auto;
  margin: 0.5rem;
  border-radius: 0.5rem;
}
.cart-product-img {
  max-width: 5rem;
  height: auto;
  margin: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.item-product-img {
  max-width: 10rem;
  height: auto;
  margin: 0.5rem;
  border-radius: 0.5rem;
}
.small-image {
  max-width: 2.5rem;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.smallest-image {
  max-width: 2rem;
  max-height: 2rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.small-image-wholesale {
  max-width: 2.5rem;
  height: auto;
  border-radius: 0.5rem;
}
/* GENERAL STYLING IFRAME COVER */
.iframe-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}
.iframe-cover-signed {
  position: fixed;
  right: 2.5rem;
  top: 4rem;
  width: 35%;
  height: max-content;
}
.positioned-img {
  width: 30rem;
  height: auto;
  border-radius: 0.5rem 0 0 0.5rem;
}
@media only screen and (max-width: 600px) {
  .iframe-cover-signed {
    position: fixed;
    right: 0;
    top: 4rem;
    width: 100%;
    height: max-content;
  }
}
/* GENERAL STYLING BORDER */
.border-none {
  border: none;
}
.beddo-border {
  border: 1px solid #ff9935;
}
.beddo-border-cart {
  border: 1px solid #ff9935;
}
.beddo-border-cart:hover {
  border: 3px solid #ff9935;
}
.linkblue-border {
  border: 1px solid #0645ad;
}
.linkblue-border:hover {
  border: 3px solid #0645ad;
}
.linkblue-bottom-border {
  border-bottom: 1px solid #0645ad;
}
.linkblue-border:hover {
  border-bottom: 2px solid #0645ad;
}
.green-border {
  border: 1px solid green;
}
.green-border-bottom {
  border-bottom: 1px solid green;
}
.light-green-border {
  border: 1px solid rgb(0, 128, 0, 0.25);
}
.light-green-border-bottom {
  border-bottom: 1px solid rgb(0, 128, 0, 0.25);
}
.light-beddo-border {
  border: 1px solid rgb(255, 153, 53, 0.15);
}
.green-border:hover {
  border: 3px solid green;
}
.black-border {
  border: 2px solid #000000;
}
.black-border-top {
  border-top: 2px solid #000000;
}
.white-border {
  border: 1px solid white;
}
.white-border3 {
  border: 3px solid white;
}
.white-border-bottom {
  border-bottom: 1px solid white;
}
.white-border-bottom3 {
  border-bottom: 3px solid white;
}
.white-border-right {
  border-right: 3px solid white;
}
.blue-border {
  border: 1px solid lightblue;
}
.sky-blue-border {
  border: 2px solid skyblue;
}
.beddo-border-3 {
  border: 3px solid #ff9935;
}
.beddo-border-dashed-3 {
  border: 3px dashed #ff9935;
}
.alert-border-1 {
  border: 1px solid red !important;
}
.alert-border {
  border: 2px solid red !important;
}
.alert-border-bottom {
  border-bottom: 1px solid red !important;
}

.alert-border-success {
  border: 2px solid #20a020 !important;
}
.beddo-border-top-bottom {
  border-top: 1px solid #ff9935;
  border-bottom: 1px solid #ff9935;
}
.beddo-border-bottom {
  border-bottom: 1px solid #ff9935;
}
.beddo-border-bottom-3 {
  border-bottom: 3px solid #ff9935;
}
.beddo-border-bottom-5 {
  border-bottom: 5px solid #ff9935;
}
.beddo-border-bottom-light {
  border-bottom: 0.1rem solid rgb(255, 153, 53, 0.25);
}
.beddo-border-top {
  border-top: 1px solid #ff9935;
}
.beddo-border-right {
  border-right: 1px solid #ff9935;
}
.beddo-border-left {
  border-left: 1px solid #ff9935;
}
.transparent-border1 {
  border: 1rem solid transparent;
}
.light-border1 {
  border: 1rem solid #f5f7fa;
}
.light-border0-1 {
  border: 0.1rem solid #f5f7fa;
}
.seperator1r-bottom {
  border-bottom: 1rem solid #f5f7fa;
}
.space-border-bottom {
  border-bottom: 10px solid white;
}
.navigation-border-bottom {
  border-bottom: 0.1rem solid rgb(128, 128, 128, 0.25);
}
.border-right-left {
  border-right: 1px solid #f5f7fa;
  border-left: 1px solid #f5f7fa;
}
.gray-border {
  border: 1px solid gray;
}

/* GENERAL STYLING RADIUS */
.rad5p {
  border-radius: 5%;
}
.rad100p {
  border-radius: 100%;
}
.rad50p {
  border-radius: 5rem;
}
.round {
  border-radius: 50%;
}
.rad2-5r {
  border-radius: 0.25rem;
}
.rad5r {
  border-radius: 0.5rem;
}
.radtopleftright5r {
  border-radius: 0.5rem 0.5rem 0 0;
}

/* GENERAL STYLING CURSOR */
.pointer {
  cursor: pointer;
}

/* GENERAL STYLING SHADOW */
.box-shadow-top {
  box-shadow: rgba(0, 0, 0, 0.06) 0px -10px 15px -7px;
}
.box-shadow-bottom {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 10px 15px -7px;
}
.shadow-bottom {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.shadow-top {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.shadow-light {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.shadow-light-turqoise {
  box-shadow: rgba(0, 255, 234, 0.5) 0px 2px 8px 0px; /* Reddish shadow */
}
.shadow-light-orange {
  box-shadow: rgba(255, 196, 0, 0.5) 0px 2px 8px 0px; /* Reddish shadow */
}
.shadow-light-greenish {
  box-shadow: rgba(0, 255, 21, 0.5) 0px 2px 8px 0px; /* Reddish shadow */
}
.shadow-light-blueish {
  box-shadow: rgba(0, 102, 255, 0.5) 0px 2px 8px 0px; /* Reddish shadow */
}
.shadow-light-reddish {
  box-shadow: rgba(255, 0, 0, 0.5) 0px 2px 8px 0px; /* Reddish shadow */
}
.shadow-light-topless {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.shadow-all {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.white-shadowbackground {
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem;
}
.white-shadowbackground-no-margin {
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
/* GENERAL STYLING SIGNATURE */

.signature {
  background-image: url("./images/imza_tugba.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: auto;
}

/* GENERAL STYLING RatingOnHover */
.ratingonhover {
  width: 17.75%;
  margin-left: -16.5rem;
  margin-top: -4.2rem;
  padding-bottom: 0.25rem;
  border-radius: 0.5rem;
  background-color: #f7d6b5;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1;
}
.ratingonhover:before {
  content: "";
  position: absolute;
  top: 60px;
  left: 100%;
  z-index: 1;
  border: solid 15px transparent;
  border-left-color: #f7d6b5;
}
.ratingonhoverjr {
  width: 2rem;
  height: 2rem;
  margin-left: -2.5rem;
  margin-top: -0.5rem;
  border-radius: 0.225rem;
  background-color: #f5f7fa;
  border: 1px solid #ff9935;
  position: absolute;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
.ratingonhoverjr:before {
  content: "";
  position: absolute;
  left: 100%;
  z-index: 1;
  border: solid 7px transparent;
  border-left-color: #ff9935;
}

/* GENERAL STYLING POSITION & TOP*/

.floatr {
  float: right;
}
.fixed {
  position: fixed;
  z-index: 1;
  top: 0;
}
.z2 {
  z-index: 2;
}
.z11 {
  z-index: 11 !important;
}
.top3r {
  top: 3rem;
}
.bottom5r {
  bottom: 5rem;
}
.bottom10r {
  bottom: 10rem;
}
.left5r {
  left: 5rem;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
  top: 0;
}
/* GENERAL STYLING PADDING */
.padxs {
  padding: 0.2rem;
}
.pad1 {
  padding: 1rem;
}
.padh {
  padding: 0.5rem;
}
.padq {
  padding: 0.25rem;
}
.padhq {
  padding: 0.125rem;
}
.padupsideh {
  padding: 0.25rem;
}
.padupsideh5 {
  padding: 0.5rem;
}
.padb-q {
  padding-bottom: 0.25rem;
}
.padb-1 {
  padding-bottom: 1rem;
}
.padupside1 {
  padding: 1rem 0;
}
.padupsideh-5 {
  padding: 0.5rem 0;
}
.padupsideq {
  padding: 0.25rem 0;
}
.padleft-5 {
  padding-left: 0.5rem;
}
.padleft1 {
  padding-left: 1rem;
}
.padright1 {
  padding-right: 1rem;
}
.padtop1 {
  padding-top: 1rem;
}

/* GENERAL STYLING MARGIN */
.mar1 {
  margin: 1rem;
}
.mar2 {
  margin: 2rem;
}
.marh {
  margin: 0.5rem;
}
.marq {
  margin: 0.25rem;
}
.marb-q {
  margin-bottom: 0.25rem;
}
.marb-5 {
  margin-bottom: 0.5rem;
}
.marb1 {
  margin-bottom: 1rem;
}
.marb1-5 {
  margin-bottom: 1.5rem;
}
.marb2 {
  margin-bottom: 2rem;
}
.marginbottom4 {
  margin-bottom: 4rem;
}
.marsideq {
  margin: 0 0.25rem;
}
.marsideh {
  margin: 0 0.5rem;
}
.marsideh1 {
  margin: 0 1rem;
}
.marside2 {
  margin: 0 2rem;
}
.marupsideq {
  margin: 0.25rem 0;
}
.marupsideh {
  margin: 0.5rem 0;
}
.marupside1 {
  margin: 1rem 0;
}
.marupside1-5 {
  margin: 1.5rem 0;
}
.marupside2 {
  margin: 2rem 0;
}
.marupside3 {
  margin: 3rem 0;
}
.margintop-25 {
  margin-top: 0.25rem;
}
.margintop-5 {
  margin-top: 0.5rem;
}
.margintop-1 {
  margin-top: 1rem;
}
.margintop1-5 {
  margin-top: 1.5rem;
}
.margintop2 {
  margin-top: 2rem;
}
.margintop1 {
  margin-top: 10rem;
}
.margintop3 {
  margin-top: 3rem;
}
.margintop4 {
  margin-top: 4rem;
}
.margintop5 {
  margin-top: 5rem;
}
.margintop7-5 {
  margin-top: 7.5rem;
}
.margintop8 {
  margin-top: 8rem;
}
.margintop30r {
  margin-top: 30rem;
}
.marginright0-1r {
  margin-right: 0.1rem;
}
.marginright0-25r {
  margin-right: 0.25rem;
}
.marginright0-5r {
  margin-right: 0.5rem;
}
.marginright1r {
  margin-right: 1rem;
}
.marginright2r {
  margin-right: 2rem;
}
.marginright20r {
  margin-right: 20rem;
}
.marginleft0-25r {
  margin-left: 0.25rem;
}
.marginleft1r {
  margin-left: 1rem;
}
.marginleft2r {
  margin-left: 2rem;
}
.marginleft3r {
  margin-left: 3rem;
}
.marginleft5r {
  margin-left: 5rem;
}
.marginleft10p {
  margin-left: 10%;
}
.marginleft5p {
  margin-left: 5%;
}
.marginleft2-5p {
  margin-left: 2.5%;
}
/* GENERAL STYLING COLOR */

.beddo-color {
  color: #ff9935;
}
.light-beddo-color {
  color: rgb(255, 153, 53, 0.15);
}
a.beddo-color:hover {
  transition: color 0.5s ease;
  color: #fbb710;
}
.dolar-green {
  color: #008000;
}
.gold-color {
  color: #ffd700;
}
.black {
  color: #0000;
}
a.black {
  color: #000;
}
a.black:hover {
  color: #fbb710;
}
.red-col {
  color: red;
}
.lightredcol {
  color: rgb(255, 0, 0, 0.5) !important;
}
.lighterred {
  color: rgb(255, 0, 0, 0.25);
}
.white {
  color: white;
}
.green {
  color: green !important;
}
.deep-green {
  color: #007f4c;
}
.ligthgreen {
  color: rgb(0, 128, 0, 0.1);
}
.grey {
  color: grey;
}
.ghost-grey {
  color: #9cb6c3;
}
.ghost-grey-chatgpt {
  color: #c9d2d3;
}
.dark-grey {
  color: #2d2e2e;
}
.lightblue {
  color: lightblue;
}
.iyzicoButtonColor {
  color: #484f56 !important;
}
.pink {
  color: pink;
}
.darkpink {
  color: palevioletred;
}
.purple {
  color: purple;
}
.purple-light {
  color: rgb(128, 0, 128, 0.5);
}
.ordersblue {
  color: #305cf9;
}
.google-color {
  color: #4285f4;
}
/* GENERAL STYLING BACKGROUND */

.nobackground {
  background-color: transparent;
}
.quantfurybck {
  background-color: #1d2027;
}
.blackbck {
  background-color: black;
}
.slightlyyellowbck {
  background-color: rgb(255, 255, 0, 0.1) !important;
}
.redbck {
  background-color: red !important;
}
.lightredbck {
  background-color: rgb(255, 0, 0, 0.25) !important;
}
.lighterredbck {
  background-color: rgb(255, 0, 0, 0.15) !important;
}
.greenbck {
  background-color: green;
}
.greenbcklight {
  background-color: rgb(0, 128, 0, 0.1) !important;
}
.greenbcklight-1 {
  background-color: rgb(0, 128, 0, 0.25) !important;
}
.adminbck {
  background-color: #fafafa;
}
.lightbck {
  background-color: #f5f7fa;
}
.beddobck {
  background-color: #ff9935 !important;
}
.lightbeddobck {
  background-color: #ff9a352a !important;
}
.greybck {
  background-color: grey !important;
}
.lightgreybck {
  background-color: lightgray !important;
}
.whitebck {
  background-color: white;
}
.whitebckwithhoverblue {
  background-color: white;
}
.whitebckwithhoverblue:hover {
  background-color: lightblue;
}
.purplelightbck {
  background-color: rgb(128, 0, 128, 0.25) !important;
}
.purplelighterbck {
  background-color: rgb(128, 0, 128, 0.15) !important;
}
.purplelightestbck {
  background-color: rgb(128, 0, 128, 0.05) !important;
}
.ordersbluebck {
  background-color: rgb(48, 92, 249, 0.25);
}
.wastebrownbck {
  background-color: rgb(121, 69, 2, 0.25);
}
/* WHOLESALE ORDER STATUSES BACKGROUNDS */
.light-turqoisebck {
  background-color: rgba(0, 255, 234, 0.25) !important;
}
.very-light-orangebck {
  background-color: rgba(255, 204, 153, 0.25) !important;
}
.light-orangebck {
  background-color: rgba(248, 185, 68, 0.25) !important;
}
.dark-orangebck {
  background-color: rgba(255, 140, 0, 0.25) !important;
}
.light-greenishbck {
  background-color: rgba(0, 255, 21, 0.25) !important;
}
.light-blueishbck {
  background-color: rgba(0, 102, 255, 0.25) !important;
}
.light-reddishbck {
  background-color: rgba(255, 0, 0, 0.25) !important;
}
/* WHOLESALE ORDER STATUSES BACKGROUNDS CHAT GPT */
.goldbck {
  background-color: #ffd700 !important;
}
.lightbluebck {
  background-color: #add8e6 !important;
}
.lightgreenbck {
  background-color: #90ee90 !important;
}
.lightsalmonbck {
  background-color: #ffa07a !important;
}
.tomatobck {
  background-color: #ff6347 !important;
}
.orangebck {
  background-color: #ffa500 !important;
}
.graybck {
  background-color: #808080 !important;
}
.steelbluebck {
  background-color: #4682b4 !important;
}
.limegreenbck {
  background-color: #32cd32 !important;
}
.orangeredbck {
  background-color: #ff4500 !important;
}
.purplebck {
  background-color: #800080 !important;
}
.darkredbck {
  background-color: #8b0000 !important;
}
/* WHOLESALE ORDER STATUSES COLOR CHAT GPT */
.limegreencolor {
  color: #32cd32 !important;
}
.orangeredcolor {
  color: #ff4500 !important;
}
.steelbluecolor {
  color: #4682b4 !important;
}
.purplecolor {
  color: #800080 !important;
}
.darkredcolor {
  color: #8b0000 !important;
}

/* STYLING BACKGROUND COLOR TRANSITIONED */
.transitioned-lightbck {
  transition: background-color 3s ease-out;
  background-color: #f5f7fa;
}
.transitioned-lighterredbck {
  transition: background-color 3s ease-out;
  background-color: rgb(255, 0, 0, 0.15) !important;
}
.transitioned-greenbcklight-1 {
  transition: background-color 3s ease-out;
  background-color: rgb(0, 128, 0, 0.25) !important;
}
.transitioned-lightgreybck {
  transition: background-color 3s ease-out;
  background-color: lightgray !important;
}
.transitioned-greenbcklight {
  transition: background-color 3s ease-out;
  background-color: rgb(0, 128, 0, 0.1) !important;
}
.transitioned-lightbeddobck {
  transition: background-color 3s ease-out;
  background-color: #ff9a352a !important;
}
.transitioned-wastebrownbck {
  transition: background-color 3s ease-out;
  background-color: rgb(121, 69, 2, 0.25) !important;
}
.transitioned-purplelightbck {
  transition: background-color 3s ease-out;
  background-color: rgb(128, 0, 128, 0.25) !important;
}

/* GENERAL STYLING PADDING */
.pup {
  padding: 0.5rem 0 0 0;
}

.pupdown {
  padding: 0.25rem 0;
}

/* //////////////////// GENERAL STYLING ALIGN \\\\\\\\\\\\\\\\\\\\ */
.txtcenter {
  text-align: center;
}
.txtright {
  text-align: right;
}
.txtleft {
  text-align: left;
}
.txtmid {
  vertical-align: middle;
}
.txtbot {
  vertical-align: bottom;
}
.txttop {
  vertical-align: top;
}

/* //////////////////// GENERAL STYLING HEADING \\\\\\\\\\\\\\\\\\\\ */

/* CENTER */
.h1c {
  font-size: 1.8rem;
  text-align: center;
  width: 100%;
  padding: 0.5rem;
}
.h2c {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  padding: 0.5rem 0;
}
.h3c {
  font-size: 1rem;
  text-align: center;
  width: 100%;
  padding: 0.25rem;
}
.h4c {
  font-size: 0.85rem;
  text-align: center;
  width: 100%;
  padding: 0.25rem;
}

/* CENTER FOR NAV */
.h4nav {
  font-size: 0.85rem;
  text-align: center;
  width: 93%;
  margin: 1rem 0;
}

/* LEFT */
.h1l {
  font-size: 1.8rem;
  width: 100%;
  padding: 0.5rem;
}
.h2l {
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
  padding: 0.5rem 0;
}
.h3l {
  font-size: 1rem;
  text-align: left;
  width: 100%;
  padding: 0.25rem;
}
.h4l {
  font-size: 0.85rem;
  text-align: left;
  width: 100%;
  padding: 0.25rem;
}

/* LEFT MARGIN REM */
.h1lmr {
  font-size: 1.8rem;
  width: 100%;
  margin: 0.5rem;
}

.pl {
  text-align: left;
  padding: 0 0 0.5rem 0;
}

/* //////////////////// GENERAL STYLING HEADING NO PADDING WITH MARGIN \\\\\\\\\\\\\\\\\\\\ */

/* CENTER */
.h1cm {
  font-size: 1.8rem;
  text-align: center;
  width: 100%;
  margin: 0.5rem;
}
.h2cm {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  margin: 0.5rem 0;
}
.h3cm {
  font-size: 1rem;
  text-align: center;
  width: 100%;
  margin: 0.25rem;
}
.h4cm {
  font-size: 0.85rem;
  text-align: center;
  width: 100%;
  margin: 0.25rem;
}
/* LEFT */
.h1lm {
  font-size: 1.8rem;
  width: 100%;
  margin: 0.5rem;
}
.h2lm {
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
  margin: 0.5rem 0;
}
.h3lm {
  font-size: 1rem;
  width: 100%;
  margin: 0.25rem;
}
.h4lm {
  font-size: 0.85rem;
  text-align: left;
  width: 100%;
  margin: 0.25rem;
}
/* //////////////////// GENERAL STYLING HEADING NO PADDING \\\\\\\\\\\\\\\\\\\\ */

/* CENTER */

.h2cnp {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}
.h3cnp {
  font-size: 1rem;
  text-align: center;
  width: 100%;
}
.h4cnp {
  font-size: 0.85rem;
  text-align: center;
  width: 100%;
}

/* LEFT */

.h1lnp {
  font-size: 1.8rem;
  width: 100%;
}
.h2lnp {
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
}
.h3lnp {
  font-size: 1rem;
  text-align: left;
  width: 100%;
}
.h4lnp {
  font-size: 0.85rem;
  text-align: left;
  width: 100%;
}

/* RIGHT NO PADDING */

.h1rnp {
  font-size: 1.8rem;
  text-align: right;
  width: 100%;
}
.h2rnp {
  font-size: 1.2rem;
  text-align: right;
  width: 100%;
}
.h3rnp {
  font-size: 1rem;
  text-align: right;
  width: 100%;
}
.h4rnp {
  font-size: 0.85rem;
  text-align: right;
  width: 100%;
}
/* //////////////////// GENERAL STYLING BORDER RADIUS \\\\\\\\\\\\\\\\\\\\ */

.child-radius-bottom {
  border-radius: inherit;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
/* //////////////////// GENERAL STYLING LINE HEIGHT \\\\\\\\\\\\\\\\\\\\ */
.lh-75 {
  line-height: 0.75rem;
}
.lh1 {
  line-height: 1rem;
}
.lh1-25 {
  line-height: 1.25rem;
}
.lh1-5 {
  line-height: 1.5rem;
}
.lh1-75 {
  line-height: 1.75rem;
}
.lh2 {
  line-height: 2rem;
}
.lh2-5 {
  line-height: 2.5rem;
}
.lh3-5 {
  line-height: 3.5rem;
}

/* //////////////////// GENERAL STYLING PLAYER/IMAGE \\\\\\\\\\\\\\\\\\\\ */
.image-wrapper {
  position: relative;
  padding-top: 100%; /* Player ratio: 100 / (1280 / 720) */
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* //////////////////// GENERAL STYLING FONT WEIGHT \\\\\\\\\\\\\\\\\\\\ */
.bold-font {
  font-weight: bold;
}
.italic-font {
  font-style: italic;
}
.franklin {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.helvetica {
  font-family: Arial, Helvetica, sans-serif;
}
.money-font {
  font-family: "Roboto Mono", monospace !important;
}
/* //////////////////// GENERAL STYLING FONT SIZE \\\\\\\\\\\\\\\\\\\\ */
.fs-45 {
  font-size: 0.45rem;
}
.fs-55 {
  font-size: 0.55rem;
}
.fs-65 {
  font-size: 0.65rem;
}
.fs-75 {
  font-size: 0.65rem;
}
.fs-80 {
  font-size: 0.8rem;
}
.fs-85 {
  font-size: 0.85rem !important;
}
.fs1 {
  font-size: 1rem !important;
}
.fs1125 {
  font-size: 1.125rem !important;
}
.fs12 {
  font-size: 1.2rem !important;
}
.fs125 {
  font-size: 1.25rem !important;
}
.fs1-3 {
  font-size: 1.3rem !important;
}
.fs1-6 {
  font-size: 1.6rem;
}
.fs1-75 {
  font-size: 1.75rem;
}
.fs1-8 {
  font-size: 1.8rem;
}
.fs2 {
  font-size: 2rem;
}
.fs3 {
  font-size: 3rem;
}
.fs6 {
  font-size: 6rem;
}
.fs15 {
  font-size: 1.5rem;
}

/* //////////////////// GENERAL STYLING HEIGHT \\\\\\\\\\\\\\\\\\\\ */
.hauto {
  height: auto;
}
/*HEIGHT MIN*/
.mh2-5r {
  min-height: 2.5rem;
}
.mh3r {
  min-height: 3rem;
}
.mh15r {
  min-height: 15rem;
}
.mh10r {
  min-height: 10rem;
}
.mh30r {
  min-height: 30rem !important;
}
/*HEIGHT VMAX*/
.vm70 {
  height: 70vmax;
}
.vm80 {
  height: 80vmax;
}
.vm90 {
  height: 90vmax;
}

/* HEIGHT VH */
.vh70 {
  height: 70vh;
}
.vh80 {
  height: 80vh;
}
.vh90 {
  height: 90vh;
}
.vh100 {
  height: 100vh;
}
.vh150 {
  height: 150vh;
}

/* HEIGHT PERCENT */
.h100p {
  height: 100% !important;
}
.h90p {
  height: 90%;
}
.h10p {
  height: 10%;
}
.h20p {
  height: 20%;
}
.h25p {
  height: 25%;
}
.h30p {
  height: 30%;
}
.h5p {
  height: 5%;
}

/* HEIGHT REM */
.h0-25r {
  height: 0.25rem;
}
.h0-5r {
  height: 0.5rem;
}
.h0-75r {
  height: 0.75rem;
}
.h1r {
  height: 1rem;
}
.h1-5r {
  height: 1.5rem;
}
.h1-75r {
  height: 1.75rem;
}
.h2r {
  height: 2rem !important;
}
.h2-25r {
  height: 2.25rem !important;
}
.h2-5r {
  height: 2.5rem !important;
}
.h3r {
  height: 3rem;
}
.h4r {
  height: 4rem;
}
.h5r {
  height: 5rem;
}
.h3r {
  height: 3rem;
}
.h4r {
  height: 4rem;
}
.h6r {
  height: 6rem;
}
.h8r {
  height: 8rem !important;
}
.h8-5r {
  height: 8.5rem !important;
}
.h9r {
  height: 9rem !important;
}
.h10r {
  height: 10rem !important;
}
.h15r {
  height: 15rem !important;
}
.h20r {
  height: 20rem;
}
.h25r {
  height: 25rem;
}
.h30r {
  height: 30rem;
}
.h35r {
  height: 35rem;
}
.h37-5r {
  height: 37.5rem;
}
.h40r {
  height: 40rem;
}
.h45r {
  height: 45rem;
}
.h50r {
  height: 50rem;
}
.h60r {
  height: 60rem;
}
.h70r {
  height: 70rem;
}
.h100r {
  height: 100rem;
}
.maxh80 {
  max-height: 81.5% !important;
}

/* //////////////////// GENERAL STYLING Z-INDEX \\\\\\\\\\\\\\\\\\\\ */
.z-1 {
  z-index: -1 !important;
}

.z1 {
  z-index: 1 !important;
}
.z20 {
  z-index: 20 !important;
}
.z1002 {
  z-index: 1002 !important;
}

/* //////////////////// GENERAL STYLING WIDTH \\\\\\\\\\\\\\\\\\\\ */

/* MAX WIDTH REM */
.mw7r {
  max-width: 7rem;
}
.mw10r {
  max-width: 10rem;
}
.mw15r {
  max-width: 15rem;
}
.mw20r {
  max-width: 20rem;
}
.mw25r {
  max-width: 25rem;
}
.mw30r {
  max-width: 30rem;
}
.mw35r {
  max-width: 35rem;
}
.mw40r {
  max-width: 40rem;
}
.mw45r {
  max-width: 45rem;
}
.mw50r {
  max-width: 50rem;
}
.mw55r {
  max-width: 55rem;
}

/* WIDTH REM */
.w1r {
  width: 1rem;
}
.w2r {
  width: 2rem;
}
.w1-25r {
  width: 1.25rem;
}
.w1-5r {
  width: 1.5rem;
}
.w2-5r {
  width: 2.5rem;
}
.w3r {
  width: 3rem;
}
.w4r {
  width: 4rem;
}
.w5r {
  width: 5rem;
}
.w6r {
  width: 6rem;
}
.w7r {
  width: 7rem;
}
.w8-5r {
  width: 8.5rem;
}
.w9-23r {
  width: 9.23rem;
}
.w10r {
  width: 10rem;
}
.w12r {
  width: 12rem;
}
.w15r {
  width: 15rem;
}
.w20r {
  width: 20rem;
}
.w25r {
  width: 25rem;
}
.w25-5r {
  width: 25.5rem;
}
.w25-75r {
  width: 25.75rem;
}
.w25-9r {
  width: 25.9rem;
}
.w26r {
  width: 26rem;
}
.w27r {
  width: 27rem;
}
.w28r {
  width: 28rem;
}
.w29r {
  width: 29rem;
}
.w30r {
  width: 30rem;
}
.w40r {
  width: 40rem;
}
.w42-5r {
  width: 42.5rem;
}
.w45r {
  width: 45rem;
}

/* WIDTH PERCENT */

/* MAX WIDTH */
.mw25p {
  max-width: 25%;
}
.mw50p {
  max-width: 50%;
}
.mw100p {
  max-width: 100%;
}

/* WIDTH */
.w2p {
  width: 2%;
}
.w3p {
  width: 3%;
}
.w5p {
  width: 5%;
}
.w7p {
  width: 7%;
}
.w8p {
  width: 8%;
}
.w10p {
  width: 10%;
}
.w12p {
  width: 12%;
}
.w13p {
  width: 13%;
}
.w15p {
  width: 15%;
}
.w17p {
  width: 17%;
}
.w18p {
  width: 18%;
}
.w20p {
  width: 20% !important;
}
.w23p {
  width: 23% !important;
}
.w24p {
  width: 24% !important;
}
.w25p {
  width: 25%;
}
.w26p {
  width: 26%;
}
.w27p {
  width: 27%;
}
.w28p {
  width: 28%;
}
.w29p {
  width: 29%;
}
.w30p {
  width: 30% !important;
}
.w32p {
  width: 32% !important;
}
.w33p {
  width: 33% !important;
}
.w35p {
  width: 35% !important;
}
.w40p {
  width: 40%;
}
.w42-5p {
  width: 42.5%;
}
.w45p {
  width: 45%;
}
.w47-5p {
  width: 47.5%;
}
.w49p {
  width: 49%;
}
.w50p {
  width: 50%;
}
.w55p {
  width: 55%;
}
.w60p {
  width: 60%;
}
.w63p {
  width: 63%;
}
.w65p {
  width: 65%;
}
.w70p {
  width: 70%;
}
.w75p {
  width: 75%;
}
.w76p {
  width: 76%;
}
.w77p {
  width: 77%;
}
.w78p {
  width: 78%;
}
.w80p {
  width: 80% !important;
}
.w85p {
  width: 85%;
}
.w90p {
  width: 90% !important;
}
.w92-5p {
  width: 92.5% !important;
}
.w95p {
  width: 95% !important;
}
.w96p {
  width: 96%;
}
.w97p {
  width: 97%;
}
.w98p {
  width: 98%;
}
.w99p {
  width: 99%;
}
.w100p {
  width: 100% !important;
}

/* GENERAL STYLING TABLE */

.td100p {
  width: 100%;
}

thead,
tfoot {
  background-color: #f5f7fa;
}

.light-background {
  background-color: #f5f7fa;
}

/* //////////////////// GENERAL STYLING FLEX ROW \\\\\\\\\\\\\\\\\\\\ */
/* INLINE */
.inline {
  display: inline-block;
}

/* FLEX GROW */
.f1 {
  flex: 1;
}
.fg1 {
  flex-grow: 1;
}
.fg2 {
  flex-grow: 2;
}
.fg3 {
  flex-grow: 3;
}
.fg4 {
  flex-grow: 4;
}
.fg5 {
  flex-grow: 5;
}
.fg6 {
  flex-grow: 6;
}
.fg7 {
  flex-grow: 7;
}
.fg8 {
  flex-grow: 8;
}
.fg9 {
  flex-grow: 9;
}
.fg10 {
  flex-grow: 10;
}

/*STYLING TEXT*/
.scroll-open {
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll {
  overflow-y: hidden;
}
.scrolly {
  overflow-x: hidden;
}
.hiddenOverFlow {
  overflow: hidden;
}
.wordbreak {
  word-wrap: break-word;
}
/* ROW */
.row-webkit {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}
.row {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}
.row-end {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.row-nowrap {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}
.row-nowrap-baseline {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
}
.row-baseline {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;
}
.row-nowrap-start {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
}
.row-screen {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  float: right;
}
.row-space {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.row-space-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.row-space-around {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
}
.row-space-evenly-align-self {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-self: flex-end;
}
.row-space-evenly-align-self-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-self: flex-start;
}
.row-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.row-left-strech {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}
.row-left-align-baseline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}
.row-left-align-baseline-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
.row-left-align-center-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.row-left-align-start-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.row-space-around-align-baseline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}
.row-left-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.row-left-nowrap-space {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.row-center-webkit {
  width: 80%;
  min-height: 1.5rem;
  font-size: 16px;
  margin-top: 0.5rem;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.row-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.row-center-nowrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.row-qna {
  display: flex;
  flex-direction: row;
}
.align-center {
  align-items: center;
}
.align-left {
  align-items: flex-start;
}
.row-right-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-grow: 1;
}
.row-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

/* //////////////////// GENERAL STYLING FLEX COL \\\\\\\\\\\\\\\\\\\\ */
.col-webkit {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
.col-jus-webkit {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
}
.col {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
.col-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.col-jus {
  height: auto;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
}
.col-n {
  display: flex;
  flex-direction: column;
}
.col-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures spacing between content */
  height: 20rem; /* h20r assumes fixed height, convert to rem or px */
}

.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col-1 {
  flex: 1 1 15rem;
}
.col-2 {
  flex: 2 1 30rem;
}
.col-3 {
  flex: 32 1 75rem;
}
.min-30 {
  min-width: 30rem;
}
.p-1 {
  padding: 1rem;
}

/* GENERAL STYLING FORM */

.form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.form-product-create {
  width: 97%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.form-input,
.form-select,
.input-wrapper-div {
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
}
.form-input-alert,
.input-wrapper-div-alert {
  width: 100%;
  height: 4rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid red !important;
}
.input-wrapper-div {
  background-color: #f5f7fa;
  padding-right: 0.2rem;
}
.ls3 {
  letter-spacing: 0.3rem;
}
.input-prefix {
  width: 1.4rem;
  font-size: 1.2rem;
  background-color: #f5f7fa;
  margin-right: 0.1rem;
  padding-bottom: 0.2rem;
}
.input-append {
  font-size: 1.2rem;
  background-color: transparent;
  margin-left: 3rem;
  position: absolute;
  padding-bottom: 0.2rem;
}
.txtarea {
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
}
.form-input-nowidth {
  height: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
}
.txtr {
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
}

/* STYLING BUTTON */

.primary-green {
  background-color: #008000;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  height: 3.5rem;
  margin: 0.5rem 0;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
}
.primary-green:hover {
  background-color: #03c703;
}
.primary-green-mh {
  background-color: #008000;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  min-height: 3.5rem;
  margin: 0.5rem 0;
  border: 0;
  cursor: pointer;
  border-radius: 0.5rem;
}

.primary {
  background-color: #ff9935;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  height: 3.5rem;
  margin: 0.5rem 0;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}

.primary:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.primary-transparent {
  color: #ff9935;
  font-size: 1.25rem;
  font-weight: bold;
  height: 3.5rem;
  margin: 0.5rem 0;
  cursor: pointer;
  border: solid 3px #ff9935;
  border-radius: 0.5rem;
}

.primary-transparent:hover {
  transition: background-color 0.5s ease;
  background-color: seashell;
}
.primary-free {
  color: white;
  background-color: #ff9935;
  font-weight: bold;
  cursor: pointer;
  border: solid 3px #ff9935;
  border-radius: 0.5rem;
}

.primary-free:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.secondary {
  background-color: gray;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  height: 3.5rem;
  margin: 0.5rem 0;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.secondary:hover {
  transition: background-color 0.5s ease;
  background-color: #000;
}
.secondary-free {
  background-color: gray;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.secondary-free:hover {
  transition: background-color 0.5s ease;
  background-color: #000;
}
.create-button {
  background-color: #008000;
  color: white;
  font-size: 0.85rem;
  font-weight: bold;
  height: 2rem;
  width: 15rem;
  margin: 1rem;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.create-button:hover {
  transition: background-color 0.5s ease;
  background-color: #008099;
}
.create-button-active {
  background-color: #ff9935;
  color: white;
  font-size: 0.85rem;
  font-weight: bold;
  height: 2rem;
  width: 15rem;
  margin: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 0;
}
.child-button {
  background-color: #5a8000;
  color: white;
  font-size: 0.85rem;
  font-weight: bold;
  height: 1.5rem;
  width: 10rem;
  margin: 0.5rem;
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
}
.child-button:hover {
  transition: background-color 0.5s ease;
  background-color: #059900;
}
button.block {
  width: 100%;
}
.small-button {
  width: 2.5rem;
  height: 2.25rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
  cursor: pointer;
}
.small-primary-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  text-align: right;
  padding: 0.25rem;
  background-color: #ff9935;
  color: white;
  border-radius: 0.25rem;
  margin: 0.5rem 0.25rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
  cursor: pointer;
}
.small-primary-button:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.small-secondary-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  text-align: right;
  padding: 0.25rem;
  background-color: gray;
  color: white;
  border-radius: 0.25rem;
  margin: 0.5rem 0.25rem;
  cursor: pointer;
  border: 0;
}
.small-secondary-button:hover {
  transition: background-color 0.5s ease;
  background-color: #000;
}
.small-primary-green {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  text-align: right;
  padding: 0.25rem;
  background-color: #008000;
  color: white;
  border-radius: 0.25rem;
  margin: 0.5rem 0.25rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
  cursor: pointer;
}
.small-primary-green:hover {
  transition: background-color 0.5s ease;
  background-color: #059900;
}
.small-close-button {
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.modalrevieweditbutton {
  width: 49%;
  height: 1.5rem;
  background-color: #ff9935;
  cursor: pointer;
  font-weight: bold;
  color: white;
}
.modalrevieweditbutton:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.modalreviewdeletebutton {
  width: 49%;
  height: 1.5rem;
  background-color: red;
  cursor: pointer;
  font-weight: bold;
}
.modalreviewdeletebutton:hover {
  transition: background-color 0.5s ease;
  background-color: rgb(165, 7, 7);
  font-weight: bolder;
}
img.small {
  width: 10rem;
  height: auto;
}
.small-primary-button-nopad {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  text-align: right;
  background-color: #ff9935;
  color: white;
  border-radius: 0.25rem;
  border: 1px solid rgb(255, 153, 53, 0.75);
  cursor: pointer;
}
.small-primary-button-nopad:hover {
  transition: background-color 0.5s ease;
  background-color: #fbb710;
}
.wholesale-cart-button {
  border: none;
  height: 1.8rem;
  width: 1.25rem;
  background-color: lightgray;
  cursor: pointer;
}

/* STYLING BLINK */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blink-effect {
  animation: blink 5.5s infinite;
}
.blink-effect-3 {
  animation: blink 1s forwards; /* Runs for 1 second */
  animation-iteration-count: 3; /* Blinks 3 times */
}

/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}

.alert-danger-bar {
  background-color: red;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  height: 3.5rem;
  margin: 0.5rem 0;
  border: 0;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 600px) {
  #root {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
/* TRANSITION STYLING */
.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut ease 8s;
  -webkit-animation: fadeOut ease 8s;
  -moz-animation: fadeOut ease 8s;
  -o-animation: fadeOut ease 8s;
  -ms-animation: fadeOut ease 8s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* //////////////////// RESET CSS \\\\\\\\\\\\\\\\\\\\ */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* //////////////////// Styling Input \\\\\\\\\\\\\\\\\\\\ */
.admin-file-upload-input {
  width: 5rem;
  border: #ff9935;
}

/* //////////////////// ProgressBar \\\\\\\\\\\\\\\\\\\\ */
.progressbar {
  width: 85%;
  height: 1.25rem;
  background-color: #f5f7fa;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 0px 1px 1px rgba(199, 194, 194, 0.45) inset;
  -webkit-box-shadow: -1px 0px 1px 1px rgba(199, 194, 194, 0.45) inset;
  -moz-box-shadow: -1px 0px 1px 1px rgba(199, 194, 194, 0.45) inset;
}
.first {
  width: 52.5%;
  height: 0.8rem;
  background-color: rgb(85, 224, 85);
  border-radius: 0.25;
  border: 0.1rem solid whitesmoke;
  margin-left: 0.2rem;
  text-align: center;
}
.last {
  width: 52.5%;
  height: 0.6rem;
  background-color: #f5f7fa;
  text-align: center;
  color: grey;
}

.shadow-platform {
  width: 95%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
}
.shadow-platform-nomargin {
  width: 95%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 1rem 1rem -1.4rem 1rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  z-index: 1;
}
.search-bar-button {
  width: 25%;
  height: 1.75rem;
  color: green;
  background-color: rgb(0, 128, 0, 0.15);
  border: none;
  border-radius: 0.025rem;
  cursor: pointer;
}
.slick-dots {
  position: static !important;
}
.screen-nav {
  background-color: #ffffff;
  font-size: 0.85rem;
  height: 2rem;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.screen-nav:hover {
  background-color: rgb(238, 238, 238);
}
.screen-nav-active {
  border-bottom: solid 1px #3274f8;
  font-size: 0.85rem;
  height: 2rem;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

/* MOBILE DEVICES */
@media only screen and (max-width: 1024px) {
  .shadow-platform,
  .shadow-platform-nomargin {
    width: 95%;
  }
}
@media only screen and (max-width: 599px) {
  .shadow-platform,
  .shadow-platform-nomargin {
    width: 95%;
  }
}
@media only screen and (max-width: 539px) {
  .shadow-platform,
  .shadow-platform-nomargin {
    width: 90%;
  }
}

/* ADMIN ORDER STATUS COLORS */
.order-success {
  background-color: rgb(0, 128, 0, 0.1) !important;
}
.order-cancelled {
  background-color: rgb(255, 0, 0, 0.1) !important;
}
.order-refund {
  background-color: rgb(255, 0, 0, 0.25) !important;
}
.order-upsell {
  background-color: rgba(2, 253, 2, 0.25) !important;
}

/* SECURED ORDER STATUS */
.secured-order-steps {
  border-bottom: 0.1rem solid rgb(255, 153, 53, 0.25);
  line-height: 1.5rem;
  color: grey;
  width: 100%;
}
.secured-order-steps-icon {
  color: grey;
  margin: 0.25rem;
}
.secured-order-steps-active {
  border-bottom: 0.1rem solid rgb(255, 153, 53);
  line-height: 1.5rem;
  color: green;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.75rem;
}
.secured-order-steps-icon-active {
  color: green;
  margin: 0.25rem;
  font-size: 1.1rem;
}

/* HOVER MESSAGE STYLING */
.hover-message-p {
  padding: 0.25rem;
  background-color: rgb(128, 128, 128, 0.25);
  border: 0.1rem solid rgba(255, 153, 53, 0.25);
  margin: 0.1rem;
}
.hover-message-span {
  position: absolute;
  background-color: rgba(245, 180, 212);
  border: 0.1rem solid rgba(255, 153, 53, 0.25);
  border-radius: 0.25rem;
}

/* STYLING MEDIA PRINT */
.print-content {
  width: 60%;
}
@media print {
  @page {
    margin-right: 1rem; /* Adjust the margin value as needed */
  }

  .print-content {
    width: 95%;
    display: block; /* Change to display block for print */
    margin: 0 auto; /* Center horizontally using margin */
  }
}
